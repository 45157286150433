interface IntersectOptions {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number | number[];
}

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('intersect', {
        // Когда элемент привязан
        mounted(el, binding) {
            // Параметры по умолчанию
            const defaultOptions: IntersectOptions = {
                root: null,
                rootMargin: '0px',
                threshold: 1.0,
            };

            // Объединяем пользовательские параметры с параметрами по умолчанию
            const options = {...defaultOptions, ...binding.value.options};

            const callback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Вызываем функцию, переданную пользователем, если элемент виден
                        binding.value.callback();
                    }
                });
            };

            const observer = new IntersectionObserver(callback, options);
            observer.observe(el);
        },
    });
});
