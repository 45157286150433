import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import close_45tooltip_45global from "/app/middleware/close-tooltip.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  close_45tooltip_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/app/middleware/admin.ts"),
  locale: () => import("/app/middleware/locale.ts")
}