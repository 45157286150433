import mitt from 'mitt'
import type {Filters} from "~/types/Filters";
import type {Order} from "~/types/Order";
import type {Modals} from "~/types/Modals";

export type Events = {
    notify: {
        success: boolean
        msg: string
    }
    open_select: boolean
    providers_loaded: boolean
    sales_chart: boolean
    comp_search: string | null
    change_modal: {
        name: keyof Modals
        value: boolean,
        title?: string,
        msg?: string,
        callbackValue?: any
    }
    reset_filter: boolean
    toggle_filters: {
        value: boolean
    }
    get_filters: Filters
    providers_compare_loaded: void
    get_prices: number[]
    update_list: void
    sort_by: Order
    order_by: string
    pagi_offers: any
    similar_skins: any
    family_skins: any
    hide_tooltip: any
    update_tooltip_text: any
    show_tooltip: Record<any, any>
    highlight_line: Record<any, any>
    highlight_point: {
        value: string,
        highlighted: boolean
    }
    confirm: boolean
    fetch_data: any
}

const emitter = mitt<Events>()

export default {
    $on: emitter.on,
    $off: emitter.off,
    $emit: emitter.emit
}
