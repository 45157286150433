export enum languagesEnum {
    EN = 'en',
    UK = 'uk',
    PL = 'pl',
    RU = 'ru',
    ES = 'es-ES',
    FR = 'fr',
    CH = 'zh-CN',
    TR = 'tr',
    DE = 'de',
    DA = 'da',
    NL = 'nl',
    PT = 'pt-PT',
    RO = 'ro',
    SV = 'sv',
}
