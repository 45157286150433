<template>
  <main class="main page-main">
    <div v-if="error" class="page-error">
      <LazyErrorsError404 v-if="error?.statusCode == 404" />
      <LazyErrorsError500 v-else :error="error" />
    </div>
  </main>
</template>

<script lang="ts" setup>
import type {NuxtError} from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

console.log(JSON.stringify(props?.error || {}, null, 2))
</script>

<style lang="scss" scoped>
.page-error {
  margin-top: 2rem;
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
