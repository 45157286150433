import {defineNuxtPlugin} from '#app';
import EventBus from '~/composables/bus';

interface TooltipBinding {
    value: string;
    arg?: 'top' | 'bottom';
}

export default defineNuxtPlugin((nuxtApp: any) => {
    let touchTimer: NodeJS.Timeout;

    const showTooltip = (event: MouseEvent | TouchEvent, binding: TooltipBinding) => {
        if (!event) return;

        // @ts-ignore
        const rect = event?.target?.getBoundingClientRect();
        const position = binding.arg || 'top';
        EventBus.$emit('show_tooltip', {
            text: binding.value,
            x: rect.left + rect.width / 2 + window.pageXOffset, // середина элемента
            y: position === 'top' ? rect.top + window.pageYOffset : rect.bottom + window.pageYOffset,
            position
        });
    };

    const hideTooltip = () => {
        clearTimeout(touchTimer);
        EventBus.$emit('hide_tooltip');
    };

    nuxtApp.vueApp.directive('tooltip', {
        mounted(el: HTMLElement, binding: TooltipBinding) {
            el.addEventListener('mouseenter', (e: MouseEvent) => showTooltip(e, binding));
            el.addEventListener('mouseleave', hideTooltip);

            el.addEventListener('touchstart', (event: TouchEvent) => {
                touchTimer = setTimeout(() => showTooltip(event?.touches?.[0], binding), 500);
            });

            el.addEventListener('touchend', hideTooltip);
            el.addEventListener('touchcancel', hideTooltip);
        },
        updated(el: HTMLElement, binding: TooltipBinding) {
            // Только обновляем текст тултипа, если он уже отображается
            EventBus.$emit('update_tooltip_text', {
                text: binding.value
            });
        },
        unmounted(el: HTMLElement) {
            el?.removeEventListener('mouseenter', showTooltip as any);
            el?.removeEventListener('mouseleave', hideTooltip);
            el?.removeEventListener('touchstart', showTooltip as any);
            el?.removeEventListener('touchend', hideTooltip);
            el?.removeEventListener('touchcancel', hideTooltip);
        }
    });
});
