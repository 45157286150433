const apiUrl = 'https://backend.csmarketcap.com/api'

export const useRequest = () => {
    const request = async (
        {
            baseUrl = apiUrl,
            path = '',
            method = 'GET',
            body = null,
            credentials = 'include'
        }: {
            baseUrl?: string
            path?: string
            method?: 'GET' | 'POST' | 'DELETE',
            body?: any,
            credentials?: 'include' | null
        }) => {
        try {
            const fullUrl = baseUrl + path;
            const options: RequestInit = {
                method,
                headers: {}
            };
            if (credentials) {
                options.credentials = credentials
            }

            if (method === 'POST' && body) {
                options.headers = {
                    'Content-Type': 'application/json',
                };
                options.body = JSON.stringify(body);
            } else if (method !== 'GET' && body) {
                options.body = JSON.stringify(body);
            }

            const response = await fetch(fullUrl, options);

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            return await response.json();
        } catch (error) {
            console.error('Error on fetch: ', error);
            throw error;
        }
    };

    return {request};
};
