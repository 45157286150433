// plugins/click-outside.ts
import {defineNuxtPlugin} from '#app';
import type {DirectiveBinding} from 'vue';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('click-outside', {
        mounted(el: HTMLElement, binding: DirectiveBinding<(event: MouseEvent) => void>) {
            el.clickOutsideEvent = function (event: MouseEvent) {
                // Проверяем, что клик был снаружи элемента и его дочерних элементов
                if (!(el === event.target || el.contains(event.target as Node))) {
                    // вызываем метод переданный в аргументах директивы v-click-outside
                    binding.value(event);
                }
            };
            // @ts-ignore
            document.addEventListener('click', el.clickOutsideEvent);
        },
        beforeUnmount(el: HTMLElement) {
            if (el.clickOutsideEvent) {
                // @ts-ignore
                document?.removeEventListener('click', el.clickOutsideEvent);
            }
        }
    });
});
