export function cleanObject<T extends object>(obj: T): Partial<T> {
    return Object.keys(obj).reduce((acc, key) => {
        let value = obj[key as keyof T];

        // Преобразование строковых чисел в действительные числа
        if (typeof value === 'string' && (value as string).trim() !== '' && !isNaN(Number(value))) {
            value = Number(value) as T[keyof T];
        }

        if (
            (Array.isArray(value) && (value as unknown[]).length === 0) || // Утверждение типа для массива
            value === null ||
            value === ''
        ) {
            return acc;
        } else {
            acc[key as keyof T] = value;
            return acc;
        }
    }, {} as Partial<T>);
}
