<template>
  <client-only>
    <noscript>
      <iframe height="0"
              src="https://www.googletagmanager.com/ns.html?id=GTM-P46R386D"
              style="display:none;visibility:hidden" width="0"></iframe>
    </noscript>
    <noscript>
      <div><img alt="" src="https://mc.yandex.ru/watch/97745802"
                style="position:absolute; left:-9999px;" /></div>
    </noscript>
    <!-- /Yandex.Metrika counter -->
  </client-only>

  <div v-if="ondev" class="ondev">
    <div class="ondev_wrap">
      <HeaderComponentsLogo />
      <p>Site is under scheduled maintenance</p>
    </div>
  </div>
  <div v-else id="app">
    <!--    <VitePwaManifest />-->

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <LazyIcons />
    <client-only>
      <LazyModals />
      <Notifications position="bottom right" />
      <UiTooltip />
    </client-only>
  </div>
</template>
<script lang="ts" setup>
import {useStore} from '~/store';
import EventBus from '~/composables/bus';
import {onBeforeUnmount, onMounted, ref} from 'vue';
import type {Currencies} from '~/types/Currencies';
import {Notifications, notify} from '@kyvg/vue3-notification';
import {useGtag} from '#imports';

const store = useStore();
const {locale, setLocaleCookie, t} = useI18n();
const route = useRoute();
const {gtag, initialize} = useGtag();
const config = useRuntimeConfig();
const indexNowKey = config.public.indexNowKey;
const oldPage = ref('')

const {data, error} = await useAsyncData('categories', store.getCategories);
if (error.value) {
  store.categories = []
} else {
  store.categories = data.value || []
}

if (!store.user) {
  const {data: userResponse, error: userError} = await useAsyncData('loadUser', getUser)

  if (userError?.value || userResponse.value?.errors?.length) {
    // console.error('Failed to load user', userError.value || userResponse.value?.errors?.[0]?.message)
  } else {
    store.user = userResponse.value?.data?.userInfo || null
  }
}


const ondev = ref(false)
const currs: Currencies = {
  usd: 1,
  eur: 0,
  try: 0,
  gbp: 0,
  krw: 0,
  ils: 0,
  jpy: 0,
  rub: 0,
  cny: 0
};

try {
  const {data, error} = await useAsyncData('getCurrencies', getCurrencies, {
    transform: (res) => ({
      ...res,
      data: res.data?.filter(item => {
        return currs[item.type?.toLowerCase() as keyof Currencies] !== undefined;
      }) || []
    }),
  });

  if (error.value || !Array.isArray(data.value?.data) || !data.value?.success) {
    store.setCurrencies(currs);
    throw new Error('Failed to load currencies');
  }

  const curs = {} as Currencies;
  for (const i of data.value?.data || []) {
    const key = i.type.toLowerCase() as keyof Currencies;

    if (currs && typeof currs[key] !== 'undefined') {
      curs[key] = i.value / 1000;
    } else if ((key as any) === 'cny') {
      curs.cny = i.value / 1000;
    }
  }
  store.setCurrencies(curs);

  const currencyName = useCookie<keyof Currencies>('currency');
  if (currencyName.value) {
    store.setCurrency(currencyName.value);
  }
} catch (e) {
  console.error('Failed to load currencies', e);
}

const handleScroll = () => {
  if (oldPage.value === route.fullPath) {
    return
  }
  const maxScroll = window.scrollY;
  const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
  const scrollPercentage = Math.round((maxScroll / totalHeight) * 100);

  if (scrollPercentage > 50) {
    gtag('event', 'csmarket-scroll', {
      event_category: 'Page Scroll',
      event_label: '50% Scroll',
      content_id: route.fullPath,
      value: scrollPercentage
    });
    oldPage.value = route.fullPath
  }
};

watch(locale, () => {
  nextTick(() => {
    const lang = useCookie('i18n_redirected')?.value || 'en'
    if (lang !== locale.value && process.client) {
      setLocaleCookie(locale.value)
      window.location.reload();
    }
  })
}, {immediate: true});

onMounted(async () => {
  initialize();
  // store.loadUser();
  window.addEventListener('scroll', handleScroll);

  EventBus.$on('notify', ({msg, success}) => {
    try {
      const title = msg;
      if (!success) {
        return notify({
          type: 'error',
          title
        });
      }
      notify({
        type: 'success',
        title
      });
    } catch (e) {
      console.log(e);
    }
  });
  if (process.env.NODE_ENV === 'development' && !store.user) {
    const {data} = await getUser()
    store.user = data?.userInfo || null
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        if (registration.active && registration.active.scriptURL?.includes('/sw.js')) {
          registration.unregister();
        }
      });
    });
  }
});

onBeforeUnmount(() => {
  window?.removeEventListener('scroll', handleScroll);
  EventBus.$off('notify');
});


useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ?
        `${titleChunk} | CSMARKETCAP.COM` :
        `${t('heads.app.title')} | CSMARKETCAP.COM`;
  },
  htmlAttrs: {
    lang: locale.value
  },
  meta: [
    {
      name: 'theme-color',
      content: '#101319'
    },
    {
      name: 'msapplication-TileColor',
      content: '#101319'
    },
    {
      name: 'apple-mobile-web-app-title',
      content: 'CS Market Cap'
    },
    {
      name: 'application-name',
      content: 'CS Market Cap'
    },
    {name: 'msvalidate.01', content: indexNowKey || ''},
    {
      name: 'google-site-verification',
      content: 'RldvZwNVVZN2iJ3kVGgaPn4gCIa-qZ8r3OMpN6VFy8Q'
    }
  ],
  link: [
    {rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: 'anonymous'},
    {rel: 'preconnect', href: 'https://fonts.googleapis.com', crossorigin: 'anonymous'},
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700;800&display=swap',
      media: 'print',
      onload: "this.media='all'"
    },
    {
      rel: 'stylesheet',
      href: 'https://use.fontawesome.com/releases/v5.0.13/css/all.css',
      media: 'print',
      onload: "this.media='all'"
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: "16x16",
      href: '/favicon-16x16.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: "32x32",
      href: '/favicon-32x32.png'
    }
  ],
});
</script>
<style lang="scss">
@use '/assets/scss/variables' as *;

.main {
  padding-top: 13rem;
  transition: 0.3s;
  @media screen and (max-width: 1115px) {
    padding-top: 5.3rem;
  }
}

.ondev {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 1000;
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .logo {
    opacity: 0.3;
  }

  p {
    font-size: 1.6rem;
    margin: 1rem;
  }
}

/* Анимация перехода между страницами */
.page-enter-active, .page-leave-active {
  transition: opacity 0.2s;
}

.page-enter, .page-leave-to /* .page-leave-active в версиях <2.1.8 */
{
  opacity: 0;
}

/* Анимация перехода между представлениями */
.view-enter-active, .view-leave-active {
  transition: opacity 0.2s;
}

.view-enter, .view-leave-to /* .view-leave-active в версиях <2.1.8 */
{
  opacity: 0;
}
</style>

