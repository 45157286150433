<template>
  <NuxtLink :to="localePath('/')" class="logo">
    <!--    <img alt="logo" height="30" src="/img/logo.svg" width="100" />-->
    <svg fill="none" height="39" viewBox="0 0 87 39" width="87" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M13.9262 0.199219C13.9262 0.199219 5 2.56959 2.5 3.19922L10 12.6992L10.5 10.5881L20.1698 14.9955L17.9845 12.1622L24.5403 14.9955L24.2281 12.7918L29.5 17.1992V14.1992L39.837 17.1992L39 14.1992L47.9536 15.3103L45.7684 12.7918H54.8215L52.6363 10.5881L58.5677 9.64366L57.3189 8.69922L63.5625 4.60663L56.5 6.69922L57.9433 3.66218L50.7632 7.75477L53.2606 4.29181L44.8318 7.12514L46.7049 4.92144L36.0909 8.3844L39.837 4.29181L29.2229 7.75477L31.7204 5.23626L26.1012 6.1807L29.8473 2.71774L19 5.23626L20.5 3.03255H13.3019C13.3019 2.50786 13.9262 0.199219 13.9262 0.199219Z"
          fill="#357E68" />
      <path
          d="M6.5 17.3992C6.5 16.7365 7.03726 16.1992 7.7 16.1992H11.3C11.9627 16.1992 12.5 16.7365 12.5 17.3992V19.7992H6.5V17.3992Z"
          fill="#FFC802" />
      <path clip-rule="evenodd"
            d="M9.99936 0C8.2981 0 7.08121 0.732664 6.35476 1.96563C5.66041 3.14411 5.45448 4.70803 5.53804 6.34984C5.70577 9.64578 7.06057 13.629 8.77576 16.5542L9.27578 17.407L9.91411 16.6522C12.0952 14.0731 13.7691 10.0662 14.1503 6.6773C14.3404 4.98757 14.221 3.3534 13.5886 2.11038C13.2671 1.47824 12.8092 0.941272 12.1926 0.565719C11.5761 0.190287 10.8402 0 9.99936 0ZM11.5623 11.0725C11.0023 12.4684 10.2874 13.8071 9.47931 14.9326C8.10246 12.2847 7.07408 8.98741 6.93623 6.27869C6.85881 4.7574 7.0673 3.51419 7.56097 2.67631C8.02249 1.89299 8.76711 1.40009 9.99902 1.4M11.5623 11.0725L12.8372 11.6536C13.5062 9.99992 13.9711 8.27034 14.1503 6.6773C14.299 5.35564 14.2581 4.06797 13.9307 2.97488L13.3015 3.03255C13.3015 2.8395 13.386 2.40496 13.4929 1.93276C13.1967 1.41014 12.8 0.961173 12.289 0.626805L12.2851 0.624288L9.99902 1.4"
            fill="#FFC802"
            fill-rule="evenodd" />
      <path
          d="M48 3.69922C48 4.52765 47.3284 5.19922 46.5 5.19922C45.6716 5.19922 45 4.52765 45 3.69922C45 2.87079 45.6716 2.19922 46.5 2.19922C47.3284 2.19922 48 2.87079 48 3.69922Z"
          fill="#FF5757" />
      <path
          d="M61 9.69922C61 10.5276 60.3284 11.1992 59.5 11.1992C58.6716 11.1992 58 10.5276 58 9.69922C58 8.87079 58.6716 8.19922 59.5 8.19922C60.3284 8.19922 61 8.87079 61 9.69922Z"
          fill="#FF5757" />
      <path
          d="M31 16.6992C31 17.5276 30.3284 18.1992 29.5 18.1992C28.6716 18.1992 28 17.5276 28 16.6992C28 15.8708 28.6716 15.1992 29.5 15.1992C30.3284 15.1992 31 15.8708 31 16.6992Z"
          fill="#FF5757" />
      <path
          d="M5.88875 30.6376C5.78222 30.6278 5.67289 30.6312 5.56229 30.6601C5.06023 30.791 4.75912 31.3066 4.88846 31.812C5.01818 32.3187 5.53055 32.6222 6.03114 32.4917C6.5332 32.3608 6.83431 31.8451 6.7046 31.3383C6.63993 31.0857 6.47941 30.8831 6.27248 30.7601L7.12969 28.1109C7.26142 28.1283 7.39808 28.1224 7.53342 28.0871C7.80701 28.0158 8.02657 27.8426 8.16664 27.6196L10.412 28.5195C10.3957 28.6318 10.401 28.7495 10.4311 28.867C10.5514 29.3371 11.0257 29.6192 11.4914 29.4977C11.957 29.3763 12.2365 28.8976 12.1162 28.4276C12.0918 28.3321 12.0499 28.2474 11.999 28.1698L13.6582 26.4037C13.9114 26.532 14.2107 26.573 14.5076 26.4956C15.1581 26.326 15.5499 25.655 15.3814 24.997C15.213 24.3389 14.5497 23.9431 13.8978 24.1131C13.2472 24.2828 12.8555 24.9537 13.0239 25.6118C13.0789 25.8262 13.187 26.0126 13.3308 26.1616L11.7082 27.8884C11.5181 27.7766 11.2845 27.7373 11.0546 27.7972C10.8464 27.8515 10.6775 27.9801 10.5629 28.1448L8.31391 27.2428C8.34297 27.0895 8.34245 26.9298 8.30147 26.7697C8.15333 26.1909 7.56917 25.8434 6.9958 25.993C6.42244 26.1425 6.07853 26.7336 6.2263 27.3108C6.303 27.6105 6.49841 27.8463 6.74665 27.9852L5.88875 30.6376Z"
          fill="#58E2B0" />
      <path
          d="M17.692 22.013C15.8777 19.6903 13.0651 18.1992 9.90736 18.1992C4.43567 18.1992 0 22.6764 0 28.1992C0 33.722 4.43567 38.1992 9.90736 38.1992C13.0418 38.1992 15.8363 36.73 17.6516 34.4367C17.3115 34.1528 17.015 33.8199 16.7748 33.4361C16.5213 33.0308 16.3634 32.5926 16.293 32.1384C14.9778 34.3058 12.6099 35.7519 9.90736 35.7519C5.77478 35.7519 2.42465 32.3705 2.42465 28.1992C2.42465 24.028 5.77478 20.6465 9.90736 20.6465C12.6122 20.6465 14.9818 22.0951 16.2963 24.2656C16.3708 23.8228 16.534 23.4013 16.7853 23.0157C17.0318 22.6259 17.3381 22.2918 17.692 22.013Z"
          fill="#58E2B0" />
      <path
          d="M49.8101 31.5693C49.8101 32.3916 49.4706 33.0008 48.7917 33.3969C48.1278 33.7776 47.2527 33.9679 46.1663 33.9679H38.1094C37.6567 33.9679 37.2116 33.9222 36.774 33.8308C36.3516 33.7242 35.9668 33.572 35.6198 33.374C35.2879 33.1608 35.0163 32.9095 34.8051 32.6201C34.609 32.3308 34.5108 31.9881 34.5108 31.5921H46.0305V29.4449H37.951C37.4983 29.4449 37.0607 29.3992 36.6383 29.3078C36.2309 29.2012 35.8688 29.0489 35.5519 28.851C35.2351 28.6377 34.9786 28.3865 34.7825 28.0971C34.6014 27.7925 34.5108 27.4347 34.5108 27.0235V25.1275C34.5108 24.6706 34.6014 24.2746 34.7825 23.9396C34.9786 23.6046 35.2351 23.3304 35.5519 23.1172C35.8688 22.904 36.2309 22.7517 36.6383 22.6604C37.0607 22.5538 37.5059 22.5005 37.9736 22.5005H46.2116C47.2979 22.5005 48.1655 22.6908 48.8142 23.0715C49.4781 23.4371 49.8101 24.0462 49.8101 24.899H38.381V27.0235H46.1889C46.6416 27.0235 47.0867 27.0692 47.5243 27.1605C47.9618 27.2367 48.3466 27.3661 48.6785 27.5489C49.0104 27.7316 49.282 27.9753 49.4932 28.2799C49.7045 28.5692 49.8101 28.9042 49.8101 29.2849V31.5693Z"
          fill="white" />
      <path
          d="M33.1445 24.899C33.1445 25.3254 33.0163 25.6529 32.7598 25.8813C32.5183 26.1097 32.2844 26.2849 32.0582 26.4067C31.7262 26.6199 31.3566 26.7874 30.9492 26.9093C30.5418 27.0159 30.1118 27.0692 29.6592 27.0692H29.3197V24.899H21.6701V31.5921H29.3424V29.2622H33.0992V31.5237C33.0992 31.9349 33.0087 32.2927 32.8277 32.5972C32.6466 32.9019 32.3977 33.1532 32.0808 33.3511C31.7791 33.5491 31.417 33.7014 30.9945 33.808C30.572 33.8994 30.1269 33.9451 29.6592 33.9451H21.2627C20.8101 33.9451 20.3801 33.8917 19.9727 33.7852C19.5654 33.6633 19.2032 33.5034 18.8864 33.3054C18.5847 33.0922 18.3432 32.8409 18.1622 32.5516C17.9811 32.2622 17.8906 31.9349 17.8906 31.5693V24.8305C17.8906 24.4955 17.9811 24.1909 18.1622 23.9168C18.3432 23.6274 18.5847 23.3837 18.8864 23.1858C19.2032 22.9726 19.5654 22.805 19.9727 22.6832C20.3801 22.5614 20.8177 22.5005 21.2853 22.5005H29.6592C30.7153 22.5005 31.5603 22.7061 32.194 23.1172C32.8277 23.5284 33.1445 24.1223 33.1445 24.899Z"
          fill="white" />
      <path
          d="M87 24.899C87 25.3254 86.8718 25.6529 86.6153 25.8813C86.3738 26.1097 86.14 26.2849 85.9136 26.4067C85.5817 26.6199 85.2121 26.7874 84.8047 26.9093C84.3974 27.0159 83.9673 27.0692 83.5147 27.0692H83.1752V24.899H75.5256V31.5921H83.1979V29.2622H86.9547V31.5237C86.9547 31.9349 86.8642 32.2927 86.6832 32.5972C86.502 32.9019 86.2532 33.1532 85.9363 33.3511C85.6346 33.5491 85.2724 33.7014 84.85 33.808C84.4275 33.8994 83.9824 33.9451 83.5147 33.9451H75.1182C74.6656 33.9451 74.2356 33.8917 73.8283 33.7852C73.4209 33.6633 73.0587 33.5034 72.7419 33.3054C72.4401 33.0922 72.1988 32.8409 72.0176 32.5516C71.8366 32.2622 71.7461 31.9349 71.7461 31.5693V24.8305C71.7461 24.4955 71.8366 24.1909 72.0176 23.9168C72.1988 23.6274 72.4401 23.3837 72.7419 23.1858C73.0587 22.9726 73.4209 22.805 73.8283 22.6832C74.2356 22.5614 74.6731 22.5005 75.1409 22.5005H83.5147C84.5708 22.5005 85.4158 22.7061 86.0495 23.1172C86.6832 23.5284 87 24.1223 87 24.899Z"
          fill="white" />
      <path
          d="M65.8786 33.9679V26.9181L61.3659 32.0452L56.8077 26.9181V33.9679H53.0207V22.5005H53.7464C53.9429 22.5005 54.1924 22.5081 54.4948 22.5234C54.8122 22.5234 55.1372 22.5691 55.4699 22.6607C55.8175 22.7522 56.1653 22.8743 56.5129 23.0269C56.8607 23.1795 57.1781 23.4084 57.4654 23.7136L61.3659 27.8336L65.221 23.7136C65.4931 23.4084 65.803 23.1795 66.1507 23.0269C66.4985 22.8743 66.8461 22.7522 67.1939 22.6607C67.5415 22.5691 67.8742 22.5234 68.1917 22.5234C68.5091 22.5081 68.7662 22.5005 68.9627 22.5005H69.6657V33.9679H65.8786Z"
          fill="white" />
    </svg>
  </NuxtLink>
</template>

<script lang="ts" setup>
import {useLocalePath} from "#i18n";

const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
.logo {
  margin-right: 2rem;
  flex-shrink: 0;

  img {
    height: 2.2rem;
  }

  svg {
    object-position: center;
    object-fit: contain;
    position: relative;
    bottom: 5px;
  }
}
</style>
